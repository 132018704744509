.Toastify__toast-container {
  width: 320px;
}

.Toastify__toast-container__toast {
  border-radius: 0.25rem;
}

.Toastify__toast-container__toast-body {
  font-family: "Outfit";
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  letter-spacing: -0.03em;
}
