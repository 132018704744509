@tailwind base;
@tailwind components;
@tailwind utilities;

body,
th {
  font-family: "Outfit", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Red Hat Display", Outfit, sans-serif;
}
